<template>
  <div>
    <b-sidebar
      id="edit-bankholiday-toggle"
      ref="modal"
      v-model="modalShow"
      header-class="headerModali"
      centered
      right
      shadow
      width="24%"
      title="Edit Bank Holidays"
      @hide="showModal"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('Edit Bank Holidays')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;"
          class="fa fa-close"
        />
      </template>
      <!-- <b-form-group
        label="Select Country State name"
        label-for="countryState"
      >
        <vue-select
          id="Name"
          v-model="form.countryStateId"
          :options="getCantons"
          :reduce="(e) => e.countryStateId"
          label="name"
        />
      </b-form-group> -->
      <div style="padding-left: 10px; padding-right: 10px;">

        <b-form-group
          label="Select bank holiday name"
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model="form.name"
          />
        </b-form-group>
        <b-form-group
          label="Select days "
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model.number="form.noOfDays"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('EmployeeStartingDay')"
          label-for="input-1"
        >
          <date-picker
            v-model="form.bankDate"
            style="width: 100%"
            value-type="format"
            placeholder="Select Date"
            :format="'YYYY-MM-DD'"
          />
        </b-form-group>
      </div>
      <div style="display: flex; gap: 10px;padding-left: 10px; padding-top: 10px;">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitAddUser"
        >
          Save
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          Close
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';




export default {
  components: {
    // flatPickr,
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['form'],
  data() {
    return {
      modalShow: false,
      inputValue: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCantons',
    ]),
  },
  mounted() {
    this.form.bankHolidayDtos.push({
      bankHolidayName: '',
      noOfDays: 0,
      bankHolidayDate: Date,
    })
    // this.loadCountryStates()
  },
  methods: {
    ...mapActions(['bankHolidays', 'loadCountryStates']),
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    },
    saveData() {
    },
    onModalHide() {
    },
    async onSubmitAddUser() {
      // console.log(this.form)
      this.$emit('editBankHoliday', this.form);
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.form.bankHolidayDtos.bankHolidayName = '';
      this.form.bankHolidayDtos.countryStateId = '';
      this.form.bankHolidayDtos.bankHolidayDate = '';
      this.form.bankHolidayDtos.noOfDays = '';


      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style scoped>
.buttonii{
  background-color: #242F6E;
    font-weight: 500;
    color: #FBFAF7;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    height: 42px;
    margin-top: 25px;
    margin-right: 20px;
    width: 100%;
}
</style>
