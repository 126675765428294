<template>
  <section
    class=""
  >
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
      {{ $t("BankHolidays") }}
    </p>
    <!-- style="padding: 80px 15px 15px 265px; padding-top: 180px; background-color: transparent" -->
    <div>
      <div style="display: flex; align-items: end; gap: 10px; padding-bottom: 15px;">
        <button
          v-b-modal.add-bank-holidays
          class="button"
        >
          {{ $t('AddBankHolidays') }}
        </button>
        <EditBankHolidays
          :form="form"
          @editBankHoliday="editBankHoliday"
        />
        <!-- <b-form-group
          id="input-group-9"
          label="Select Country State name"
          label-for="input-9"
        >
          <vue-select
            v-model="countryStateId"
            :options="getCantons"
            :reduce="(e) => e.countryStateId"
            label="name"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group> -->
        <b-form-group
          id="input-group-9"
          :label="$t('Canton')"
          label-for="input-9"
          style="margin-right: 10px; width: 15%; margin-top: 5px"
        >
          <b-form-select
            v-if="getCantons"
            ref="name"
            v-model="countryStateId"
            :clearable="false"
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :reduce="(e) => e.countryStateId"
            :options="selectCanton"
            label="name"
            :placeholder="$t('Select')"
          />
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('Filter date')"
          label-for="input-9"
          style="margin-bottom: 10px"
        >
          <div class="year">
            <button @click="previousDay">
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-control"
              format="YYYY"
              type="date"
              placeholder="Select Date"
              style="width: 193px; border: none;"
            />
            <button
              style="margin-left: 5px"
              @click="nextDay"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>

        <button
          v-b-tooltip.top
          :title="$t('MoveAllBank')"
          class="button"
          @click="handleCopyBankHolidays"
        >
          {{ $t("MoveAllDays") }}
        </button>
      </div>
      <div
        style="width: 100%; padding-top: 16px"
        class="scroll"
      >
        <table
          class="team_table"
          style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); font-size: 12px"
        >
          <thead>
            <tr>
              <th style="padding: 10px; border-radius: 10px 0px 0px 0px; text-align: left;">
                {{ $t("Name of the Bank Holiday") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("Number of days") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left;">
                {{ $t("Year") }}
              </th>
              <th style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;">
                {{ $t("Edit") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in getBankHolidays"
              :key="index.id"
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: left;
                cursor: pointer;
              "
            >
              <!-- @click="matProps(user), showSameId(user.userId), loadVacation(user.userId)" -->
              <!-- <td style="padding: 9px">
                {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
              </td> -->
              <td style="padding: 9px">
                {{ item.name }}
              </td>
              <td style="padding: 9px">
                {{ item.noOfDays }}
              </td>
              <td style="padding: 9px">
                {{ dateFormat(item.bankDate) }}
              </td>
              <td
                style="padding: 9px; text-align: left; cursor: pointer"
              >
                <p
                  v-b-toggle.edit-bankholiday-toggle
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil @click="getBankHolidayData(item)" />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddBankHolidays @pushBankHoliday="BankHoliday" />

  </section>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone'
import DatePicker from 'vue2-datepicker';
import AddBankHolidays from './employees/modals/AddBankHolidays.vue';
import EditBankHolidays from './employees/modals/EditBankHolidays.vue';
import 'vue2-datepicker/index.css';






export default {
  components: {
    AddBankHolidays,
    EditBankHolidays,
    DatePicker,
  },
  data() {
    return {
      countryStateId: '3f1b57e7-4dc1-424d-893f-08db31f6edb4',
      selectedDate: moment().format('YYYY-MM-DD'),
      form: {},
    }
  },
  computed: {
    ...mapGetters([
      'getBankHolidays',
      'getCantons',
      'getIsLoading',
    ]),
    selectCanton() {
      const filiales = this.getCantons
        .filter((option) => { return option.countryStateId && option.name }) // Filter out objects without value or text
        .map((option) => {
          return {
            value: option.countryStateId,
            text: option.name,
          }
        });

      return filiales;
    },
  },
  watch: {
    countryStateId(value) {
      this.loadBankHolidays({
        countryStateId: value,
        year: this.selectedDate,
      });
    },
    selectedDate(value) {
      this.loadBankHolidays({
        countryStateId: this.countryStateId,
        year: value,
      })
    },
  },
  mounted() {
    this.loadCountryStates();
    this.loadBankHolidays({
      countryStateId: this.countryStateId,
      year: this.selectedDate,
    });
  },
  methods: {
    ...mapActions(['bankHolidays', 'loadBankHolidays', 'loadCountryStates', 'editBankHolidays', 'copyBankHolidays']),

    BankHoliday(obj) {
      this.bankHolidays({
        object: obj,
        successCallback: () => {
          this.loadBankHolidays({
            countryStateId: this.countryStateId,
            year: this.selectedDate,
          });
        },
      });
    },
    async handleCopyBankHolidays() {
      const previousYear = moment(this.selectedDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
      await this.copyBankHolidays({
        countryStateId: this.countryStateId,
        year: previousYear,
        successCallback: () => {
          this.loadBankHolidays({
            countryStateId: this.countryStateId,
            year: this.selectedDate,
          });
        },
      })
    },
    editBankHoliday(value) {
      this.editBankHolidays({ bankHolidayId: value.bankHolidayId, object: { bankHolidayName: value.name, bankHolidayDate: value.bankDate, noOfDays: value.noOfDays } })
    },
    getBankHolidayData(value) {
      this.form = value;
    },
    // async fetchCountryStates() {
    //   const year = '2023-07-11';
    //   const countryStateId = '3f1b57e7-4dc1-424d-893f-08db31f6edb4'
    //   await this.loadBankHolidays({ countryStateId, year });
    // },
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD');
    },
    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    moveAllDays() {
      const bankHolidays2023 = this.getBankHolidays;

      const bankHolidays2024 = bankHolidays2023.map((holiday) => {
        return {
          bankHolidayName: holiday.name,
          bankHolidayDate: moment(holiday.bankDate, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD'),
          noOfDays: holiday.noOfDays,
          bankHolidayId: holiday.bankHolidayId,
        };
      });

      const allBankHolidays = [...bankHolidays2024];


      this.bankHolidays({
        object: {
          countryStateId: this.countryStateId,
          bankHolidayDtos: allBankHolidays,
        },
      });
    },
  },


};
</script>

<style scoped>
.form-group{
  margin-bottom: 0px !important;
}

.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button{
  width: 210px !important;
}
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

</style>
